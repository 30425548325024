import "./More.css"
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

function randomizeDivs() {
    const divs = document.querySelectorAll('.more-item');
    if (divs === null){
        return;
    }
    const minDistance = 0.1;
    divs.forEach((div) => {
        const divWidth = div.offsetWidth;
        const divHeight = div.offsetHeight;
        const maxX = window.innerWidth - divWidth - minDistance * window.innerWidth;
        const maxY = window.innerHeight - divHeight - minDistance * window.innerHeight;
        const randomX = Math.random() * maxX + minDistance * window.innerWidth;
        const randomY = Math.random() * maxY + minDistance * window.innerHeight;
        const randomRotation = Math.random() * 20 - 10;
        div.style.left = `${randomX}px`;
        div.style.top = `${randomY}px`;
        div.style.transform = `rotate(${randomRotation}deg)`;
    });
}

export default function More(){
    const navigate = useNavigate();
    useEffect(() => {
        randomizeDivs();
    }, []);
    return (
        <>
            <div className="more-background"></div>
            <div className="more-back" onClick={()=>{navigate('/');}}>&lt;&lt; back</div>
            <div className="more-container">
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>WIP</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>black</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>tetris</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>sound</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>chocolate</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>draw</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>python</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>hiking</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>trains</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>run</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>metal</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>rain</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>sharpen</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>space</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>forest</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>survival</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>fire</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>backgammon</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>whiskey</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>Ψ</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>⛰️</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>♠️️</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>♥️️</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>👍🏻️</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>🌶</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>🥃</div>
                <div className="more-item" onMouseOver={()=>{randomizeDivs()}}>🩸</div>
            </div>
        </>
    )
}