import Home from './HomePage/Home.js'
import {Route, Routes} from "react-router-dom";
import Dev from "./PageDev/Dev";
import DIY from "./PageDIY/DIY";
import About from "./PageAbout/About";
import Cook from "./PageCook/Cook";
import Animator from "./PageAnimator/Animator";
import Write from "./PageWriter/Write";
import More from "./PageMore/More";

export default function App(){
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dev" element={<Dev />} />
            <Route path="/DIY" element={<DIY />} />
            <Route path="/cook" element={<Cook />} />
            <Route path="/art" element={<Animator />} />
            <Route path="/write" element={<Write/>} />
            <Route path="/more" element={<More />} />
            <Route path="/info" element={<About />} />
        </Routes>
    )
}