import './DIY.css'
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

let shakeIntervalId = null;

function shakeText(){
    const container = document.querySelector(".diy-placeholder");
    const drill = document.querySelector(".diy-drill");
    if (container === null || drill === null){
        return;
    }
    const shakeInterval = 50;
    function getRandomValue(min, max) {
        return Math.random() * (max - min) + min;
    }
    function applyShakeEffect() {
        const rotation = getRandomValue(-4, -1);
        const translateX = getRandomValue(-5, 5);
        const translateY = getRandomValue(-5, 5);
        container.style.transform = `translateX(${translateX}px) translateY(${translateY}px) rotate(${rotation}deg)`;
        setTimeout(() => {
            container.style.transform = "none";
        }, shakeInterval);
    }


    shakeIntervalId = setInterval(applyShakeEffect, shakeInterval);
}

function stopShake() {
    if (shakeIntervalId !== null) {
        clearInterval(shakeIntervalId);
        shakeIntervalId = null;
        const container = document.querySelector(".diy-placeholder");
        if (container === null){
            return;
        }
        container.style.transform = "none";
    }
}

let isShaking = false;

function startAlternatingShake() {
    if (!isShaking) {
        shakeText();
        const drill = document.querySelector(".diy-drill");
        if (drill !== null){
            if (window.innerWidth < 900) {
                drill.style.transform = "scaleX(-1) rotate(-90deg)";
            } else {
                drill.style.transform = "none";
            }
        }
        isShaking = true;

        setTimeout(() => {
            stopShake();
            isShaking = false;
            const drill = document.querySelector(".diy-drill");
            if (drill !== null){
                if (window.innerWidth < 900) {
                    drill.style.transform = "scaleX(-1) rotate(-90deg) translateX(-100px)";
                } else {
                    drill.style.transform = "translateX(-100px) rotate(-10deg)";
                }
            }

            setTimeout(() => {
                startAlternatingShake();
            }, 3000);
        }, 1000);
    }
}

export default function DIY() {
    const navigate = useNavigate();
    useEffect(() => {
        startAlternatingShake();
    }, []);
    return (
    <>
        <div className="diy-background"></div>
        <div className="diy-back" onClick={()=>{navigate('/');}}>&lt;&lt; înapoi</div>
        <div className="diy-placeholder">
            <div className="diy-placeholder-inside">
                la pagina asta încă se lucrează.<br/>
                totuși, dacă ai nevoie de un meseriaș,<br/>
                nu-ți recomand cu încredere să&nbsp;
                <div onClick={() => {navigate('/info')}} style={{textDecoration: "underline", cursor: "pointer"}}>mă contactezi</div>.
            </div>
        </div>
        <div className="diy-drill"></div>
    </>
    )
}