import './About.css'
import {useNavigate} from "react-router-dom";
import picMyself from "../images/about/info-pic.png"

export default function About() {
    const navigate = useNavigate();
    return (
        <>
            <div className="about-background"></div>
            <div className="about-back" onClick={()=>{navigate('/');}}>&lt;&lt; home</div>
            <div className="about-action" onClick={() => window.open("mailto:negr.alexandru@gmail.com")}>let's get in touch.</div>
            <img className="about-picture" alt="Picture Of Me" src={picMyself}/>
            <div className="about-name">ALEXANDRU NEGRU</div>
            <div className="about-title">factotum based in cluj-napoca</div>
            <div className="about-left-container">
                <div className="about-languages">
                    <div className="about-language-item">
                        <div className="about-language-icon" id="about-flag-ro"></div>
                        <div className="about-language-description">nativ</div>
                    </div>
                    <div className="about-language-item">
                        <div className="about-language-icon" id="about-flag-us"></div>
                        <div className="about-language-description">fluent</div>
                    </div>
                    <div className="about-language-item">
                        <div className="about-language-icon" id="about-flag-fr"></div>
                        <div className="about-language-description">un petit peu</div>
                    </div>
                    <div className="about-language-item">
                        <div className="about-language-icon" id="about-flag-es"></div>
                        <div className="about-language-description">un poquito</div>
                    </div>
                </div>
                <div className="about-sections">
                    <div className="about-section-item" onClick={()=>{navigate('/dev');}}>&gt; developer</div>
                    <div className="about-section-item" onClick={()=>{navigate('/art');}}>&gt; animator</div>
                    <div className="about-section-item" onClick={()=>{navigate('/cook');}}>&gt; cook</div>
                    <div className="about-section-item" onClick={()=>{navigate('/write');}}>&gt; writer</div>
                    <div className="about-section-item" onClick={()=>{navigate('/DIY');}}>&gt; bricoleur</div>
                    <div className="about-section-item" onClick={()=>{navigate('/more');}}>&gt; more</div>
                </div>
            </div>
            <div className="about-right-container">
                <div className="about-data">
                    <div className="about-data-item" id="about-data-location"
                         onClick={() => window.open("https://www.google.ro/maps/place/Rom%C3%A2nia/")}>
                        find me in
                    </div>
                    <div className="about-data-item" id="about-data-email"
                         onClick={() => window.open("mailto:negr.alexandru@gmail.com")}>
                        email
                    </div>
                    <div className="about-data-item" id="about-data-github"
                         onClick={() => window.open("https://github.com/BlackAlexander")}>
                        github
                    </div>
                    <div className="about-data-item" id="about-data-linkedin"
                         onClick={() => window.open("https://www.linkedin.com/in/alexandru-negru-914619293/")}>
                        linkedin
                    </div>
                    <div className="about-data-item" id="about-data-overflow"
                         onClick={() => window.open("https://stackoverflow.com/")}>
                        stack overflow
                    </div>
                </div>
            </div>
            <div className="about-copyright">© 2023&nbsp;<a href="https://www.alexandru.black"> www.alexandru.black</a>. All Rights Reserved.</div>
        </>
    )
}