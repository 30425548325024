import "./Cook.css"
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import platesdata from './cook-plates.json'
import cookLoading from '../images/cook/cook-loading.png'

function randomPosition(){
    if (window.innerWidth < 700){
        return [20, 20, 0];
    }
    let margin1 = 10,
        margin2,
        margin3,
        margin4,
        top = 40,
        bottom = 280;
    const cookPlatePicture = document.querySelector(".cook-plate-picture");
    const computedStylePicture = window.getComputedStyle(cookPlatePicture);
    margin2 = Number(String(computedStylePicture.getPropertyValue("left")).slice(0,-2)) - 110;
    margin3 = margin2 + 120 + Number(String(computedStylePicture.getPropertyValue("width")).slice(0,-2));
    margin4 = margin3 + Number(String(computedStylePicture.getPropertyValue("right")).slice(0,-2)) - 110;

    let positionX,
        positionY,
        isLeft;

    isLeft = Math.random() < 0.5;
    if (isLeft) {
        positionX = Math.random() * (margin2 - margin1) + margin1;
    } else {
        positionX = Math.random() * (margin4 - margin3) + margin3;
    }
    positionY = Math.random() * (bottom - top) + top;

    return [positionY, positionX, Math.floor(Math.random() * 41) - 20];

}

function generatePositions(count) {
    const toReturn = [];
    const maxAttempts = 20;
    for (let i = 0; i < count; i++) {
        let attempts = 0;
        let newPositions;
        while (attempts < maxAttempts) {
            newPositions = randomPosition();
            let valid = true;
            for (let j = 0; j < i; j++) {
                if (
                    Math.abs(newPositions[0] - toReturn[j][0]) < 70 ||
                    Math.abs(newPositions[1] - toReturn[j][1]) < 150
                ) {
                    valid = false;
                    break;
                }
            }
            if (valid) {
                toReturn.push([newPositions[0], newPositions[1], newPositions[2]]);
                break;
            } else {
                attempts++;
            }
        }

        if (attempts >= maxAttempts) {
            newPositions = randomPosition();
            toReturn.push([newPositions[0], newPositions[1], newPositions[2]]);
        }
    }

    return toReturn;
}

function arrangeContains(){
    const allContains = document.getElementsByClassName("cook-plate-contains");

    for (let i = 0; i < allContains.length; i++) {
        const children = allContains[i].getElementsByTagName('div');
        const positions = generatePositions(children.length);
        for (let j = 0; j < children.length; j++) {
            const item = children[j];
            item.style.top = String(positions[j][0]) + "px";
            item.style.left = String(positions[j][1]) + "px";
            item.style.transform = "rotate(" + String(positions[j][2]) + "deg)";
        }
    }
}

function CookPlate({ item, index }) {
    const [imageError, setImageError] = useState(false);

    const handleImageError = () => {
        if (!imageError) {
            setImageError(true);
        }
    };

    useEffect(() => {
        if (imageError) {
            const retryTimeout = setTimeout(() => {
                setImageError(false);
            }, 1000);

            return () => clearTimeout(retryTimeout);
        }
    }, [imageError]);

    return (
        <img
            className="cook-plate-picture"
            src={imageError ? cookLoading : item.picture}
            alt="Cook Plate"
            onError={handleImageError}
        />
    );
}

function getPlates(platesData) {
    return (
        <>
            {platesData.map((item, index) => (
                <div key={`plate-${index}`} className="cook-plate" id={`plate-${index}`}>
                    <CookPlate item={item} index={index}/>
                    <div className="cook-plate-title">{item.title}</div>
                    <div className="cook-plate-contains">
                        {item.contains.map((ingredient, i) => (
                            <div
                                key={`ingredient-${i}`}
                                className="cook-plate-contains-item"
                            >
                                {ingredient}
                            </div>
                        ))}
                    </div>
                    <div className="cook-plate-decoration"></div>
                </div>
            ))}
        </>
    );
}

export default function Cook(){
    const navigate = useNavigate();
    const [plates, setPlates] = useState([]);

    useEffect(() => {
        setPlates(platesdata.plates);
    }, []);

    useEffect(() => {
        arrangeContains();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [plates]);

    const handleResize = () => {
        arrangeContains();
    };

    return (
        <>
            <div className="cook-background"></div>
            <div className="cook-back" onClick={() => {navigate('/')}}>&lt;&lt; more</div>
            <div className="cook-country">
                <div className="cook-flag-blue"></div>
                <div className="cook-flag-yellow"></div>
                <div className="cook-flag-red"></div>
                <span className="cook-flag-hint">This page is in Romanian.</span>
            </div>
            <div className="cook-title">doar mâncare autentică.</div>
            <div className="cook-name">ALEXANDRU NEGRU</div>
            <div className="cook-intro-image"></div>
            <div className="cook-image-paprika"></div>
            <div className="cook-image-pan"></div>

            {getPlates(plates)}
            <div className="cook-development">Pagina este încă dezvoltare. Optimizarea încărcării datelor și compresia pozelor urmează a fi încă făcută.</div>
            <div className="cook-dummy-space"></div>
        </>
    )
}