import {useEffect, useState} from "react";

export default function CenterFlash(){
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleMouseMove = (event) => {
        setPosition({ x: event.clientX - window.innerWidth/2, y: event.clientY - window.innerHeight/2});
        if (window.innerWidth < 600){
            setPosition({ x: 0, y: 0})
        }
    };

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, []); // Empty dependency array ensures the effect only runs once
    return <div className="center-flash"
                style={{
                    marginLeft: position.x*0.04 + 'px',
                    marginTop: position.y*0.04 + 'px',
                }}>
    </div>
}