import './Home.css'
import {useNavigate} from "react-router-dom";
import {
    enlargeSatellite,
    ensmallSatellite,
    getRandomBackground,
    returnToBase,
    setConsoleNaming,
    setFarAway
} from "./HomeAUX";
import CenterFlash from "./CenterFlash";

export default function Home() {
    let backgroundImage = getRandomBackground();
    const navigate = useNavigate();

    const getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    const delayedNavigate = (path) => {
        if (getScreenWidth() < 1000) {
            setTimeout(() => {
                navigate(path);
            }, 1000);
        } else {
            navigate(path);
        }
    };

    return (
        <>
            <div className="home-background" style={{backgroundImage: `url(${backgroundImage})`}}></div>
            <CenterFlash />
            {/*<div className="center-flash"> </div>*/}
            <div className="satellites">
                <div className="satellite-animation"
                     onMouseOver={() => {setFarAway(0);enlargeSatellite(0);setConsoleNaming(0);}}
                     onMouseOut={() => {returnToBase(); ensmallSatellite(0);setConsoleNaming(7);}}
                     onClick={() => {delayedNavigate('/art')}}>
                    <div className="home-icon-AN"></div>
                </div>
                <div className="satellite-cooking"
                     onMouseOver={() => {setFarAway(1); enlargeSatellite(1);setConsoleNaming(1);}}
                     onMouseOut={() => {returnToBase(); ensmallSatellite(1);setConsoleNaming(7);}}
                     onClick={() => {delayedNavigate('/cook')}}>
                    <div className="home-icon-CK"></div>
                </div>
                <div className="satellite-writing"
                     onMouseOver={() => {setFarAway(2); enlargeSatellite(2);setConsoleNaming(2);}}
                     onMouseOut={() => {returnToBase(); ensmallSatellite(2);setConsoleNaming(7);}}
                     onClick={() => {delayedNavigate('/write')}}>
                    <div className="home-icon-WR"></div>
                </div>
                <div className="satellite-about"
                     onMouseOver={() => {setFarAway(3); enlargeSatellite(3);setConsoleNaming(3);}}
                     onMouseOut={() => {returnToBase(); ensmallSatellite(3);setConsoleNaming(7);}}
                     onClick={() => {delayedNavigate('/info')}}>
                    <div className="home-icon-AB"></div>
                </div>
                <div className="satellite-misc"
                     onMouseOver={() => {setFarAway(4); enlargeSatellite(4);setConsoleNaming(4);}}
                     onMouseOut={() => {returnToBase(); ensmallSatellite(4);setConsoleNaming(7);}}
                     onClick={() => {delayedNavigate('/more')}}>
                    <div className="home-icon-MS"></div>
                </div>
                <div className="satellite-diy"
                     onMouseOver={() => {setFarAway(5); enlargeSatellite(5);setConsoleNaming(5);}}
                     onMouseOut={() => {returnToBase(); ensmallSatellite(5);setConsoleNaming(7);}}
                     onClick={() => {delayedNavigate('/DIY')}}>
                    <div className="home-icon-DY"></div>
                </div>
                <div className="satellite-cs"
                     onMouseOver={() => {setFarAway(6); enlargeSatellite(6);setConsoleNaming(6);}}
                     onMouseOut={() => {returnToBase(); ensmallSatellite(6);setConsoleNaming(7);}}
                     onClick={() => {delayedNavigate('/dev')}}>
                    <div className="home-icon-CS"></div>
                </div>
            </div>
            <div className="console-bar">/&gt; man</div>
        </>
    )
}