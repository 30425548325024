import './Dev.css'
import devStack from './dev-stack.json'
import devContact from './dev-contact.json'
import devPAC from './dev-achieve.json'
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

function goLink(theLink){
    if (theLink !== "#"){
        window.open(theLink, "_blank");
    }
}

function getContactInfo(contactData) {
    return (
        <div className="dev-contact">
            {contactData.map((item, index) => (
                <div key={`contact-item-${index}`} className="dev-contact-item" onClick={()=>{goLink(item.link)}}>
                    <div>{item.desc}</div>
                    <div>{item.info}</div>
                </div>
            ))}
        </div>
    );
}

function getStackInfo(stackData) {
    return (
        <div className="dev-stack">
            <div className="dev-stack-title">SKILL</div>
            {stackData.map((item, index) => (
                <div key={`stack-item-${index}`} className="dev-stack-item">
                    {/*<div>{item.icon}</div>*/}
                    <div>{item.info}</div>
                </div>
            ))}
        </div>
    );
}

function getPAC(pacDATA, chosenDATA) {

    let currentData;
    if (chosenDATA === "projects"){
        currentData = pacDATA.projects;
    } else if (chosenDATA === "awards"){
        currentData = pacDATA.awards;
    } else if (chosenDATA === "certificates"){
        currentData = pacDATA.certificates;
    } else {
        currentData = [];
    }

    if (currentData === undefined){
        return (<div> </div>)
    }

    return (
        <div className="dev-PAC-holder">
            {currentData.map((item, index) => (
                <div key={`pac-item-${index}`} className="dev-PAC-item" onClick={()=>{goLink(item.link)}} style={item.link !== "#" ? { cursor: 'pointer' } : {}}>
                    {item.link !== "#" && <span className="dev-PAC-link">🔗 </span>}
                    {item.title}
                    <span className="dev-PAC-item-year">{item.year}</span>
                    <div className="dev-PAC-item-description" >{item.description}</div>
                    {index !== currentData.length - 1 && <hr />}
                </div>
            ))}
        </div>
    );
}

function choosePAC(newPAC){
    const divPacProject = document.getElementById("pac-projects"),
        divPacAward = document.getElementById("pac-awards"),
        divPacCertificate = document.getElementById("pac-certificates"),
        thisPAC = document.getElementById("pac-"+newPAC),
        divTitle = document.querySelector(".dev-PAC-title"),
        container = document.querySelector(".dev-PAC-holder");

    if (divPacProject === null || divPacAward === null || divPacCertificate === null || thisPAC === null || divTitle === null || container === null){
        return;
    }

    divPacProject.style.filter = "grayscale(1)";
    divPacAward.style.filter = "grayscale(1)";
    divPacCertificate.style.filter = "grayscale(1)";
    thisPAC.style.filter = "grayscale(0)";
    divTitle.innerHTML = newPAC.toUpperCase();
    container.scrollTop = 0;
}

export default function Dev(){
    const [contactData, setContactData] = useState([]);
    const [stackData, setStackData] = useState([]);
    const [pacData, setPacData] = useState([]);
    const [selectedPAC, setSelectedPAC] = useState("projects");
    const navigate = useNavigate();

    useEffect(() => {
        setContactData(devContact);
        setStackData(devStack);
        setPacData(devPAC);
        choosePAC("projects");
        handleMouseLeave();
    }, []);

    function handleMouseEnter(){
        const devpac = document.querySelector(".dev-PAC");
        if (devpac !== undefined){
            devpac.style.filter = 'grayscale(0)';
        }
    }

    function handleMouseLeave() {
        const devpac = document.querySelector(".dev-PAC");
        if (devpac !== undefined && window.innerWidth > 800){
            devpac.style.filter = 'grayscale(1)';
        }
    }

    return (
        <>
            <div className="dev-background"></div>
            <div className="dev-back" onClick={() => {navigate('/')}}>&lt;&lt;&nbsp; &nbsp;m o r e</div>
            <div className="dev-about">
                <div className="dev-name">ALEXANDRU &nbsp;<span>NEGRU</span></div>
                <div className="dev-description">
                    i am the developer. <br/>
                    {/*Tile insipid brutes, team this reload music merge.*/}
                </div>
                <div className="dev-picture"></div>
            </div>
            {getContactInfo(contactData)}
            <div className="dev-education">
                <div className="dev-education-picture"></div>
                <div className="dev-education-title">education</div>
                <div className="dev-education-item">
                    <div className="dev-education-period">2020 - 2023</div>
                    <div className="dev-education-diploma">Bachelor of Computer Science</div>
                    <div className="dev-education-institution">Babeș-Bolyai University of Cluj-Napoca</div>
                </div>
            </div>
            <div className="dev-experience">
                <div className="dev-experience-picture"></div>
                <div className="dev-experience-title">experience</div>
                <div className="dev-experience-item">
                    <div className="dev-experience-period">2022</div>
                    <div className="dev-experience-position">Web Developer Intern</div>
                    <div className="dev-experience-company">@ SectorLabs</div>
                    <div className="dev-experience-location">Cluj-Napoca</div>
                </div>
                <div className="dev-experience-item">
                    <div className="dev-experience-period">2023</div>
                    <div className="dev-experience-position">Web Developer Intern</div>
                    <div className="dev-experience-company">@ BitStone</div>
                    <div className="dev-experience-location">Cluj-Napoca</div>
                </div>
            </div>
            <div className="dev-PAC"
                 onMouseEnter={handleMouseEnter}
                 onMouseLeave={handleMouseLeave}>
                <div className="dev-PAC-nav-buttons">
                    <div className="dev-PAC-nav-button-item" id="pac-projects" onClick={() => {setSelectedPAC("projects"); choosePAC("projects");}}>🛠️</div>
                    <div className="dev-PAC-nav-button-item" id="pac-awards" onClick={() => {setSelectedPAC("awards"); choosePAC("awards");}}>🏆</div>
                    <div className="dev-PAC-nav-button-item" id="pac-certificates" onClick={() => {setSelectedPAC("certificates"); choosePAC("certificates");}}>📜</div>
                </div>
                <div className="dev-PAC-title">PROJECTS</div>
                {getPAC(pacData, selectedPAC)}
            </div>
            <a href="https://www.bit.ly/cvnegrualexandru" target="_blank" className="dev-CV" onClick={() => {}}>CV</a>
            {getStackInfo(stackData)}
            <div className="dev-dummy-space"></div>
        </>
    )
}