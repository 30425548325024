import Image1 from "../images/backgrounds/BG001.jpg";
import Image2 from "../images/backgrounds/BG002.jpg";
import Image3 from "../images/backgrounds/BG003.jpg";
import Image4 from "../images/backgrounds/BG004.jpg";
import Image5 from "../images/backgrounds/BG005.jpg";
import Image6 from "../images/backgrounds/BG006.jpg";
import Image7 from "../images/backgrounds/BG007.jpg";
import Image8 from "../images/backgrounds/BG008.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8];

export function getRandomBackground() {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
}

function getLeftChild(nthChild){
    if (nthChild === 0){
        return 6;
    } else {
        return nthChild - 1;
    }
}

function getRightChild(nthChild){
    if (nthChild === 6){
        return 0;
    } else {
        return nthChild + 1;
    }
}

export function setFarAway(nthChild){
    const rotations = [-64.26, -12.84, 38.58, 90, 141.42, 192.84, 244.26],
        satellites = document.querySelector(".satellites").children,
        listOfKids = [0, 0, 0, 0, 0, 0];
    listOfKids[0] = getLeftChild(nthChild);
    listOfKids[1] = getRightChild(nthChild);
    listOfKids[2] = getLeftChild(getLeftChild(nthChild));
    listOfKids[3] = getRightChild(getRightChild(nthChild));
    listOfKids[4] = getLeftChild(getLeftChild(getLeftChild(nthChild)));
    listOfKids[5] = getRightChild(getRightChild(getRightChild(nthChild)));
    let satelliteOffset = getComputedStyle(document.documentElement).getPropertyValue('--satellite-offset');
    if (window.innerWidth < 600) {
        satelliteOffset = "max(100px, 15vh)";
    }
    satellites[listOfKids[0]].style.transform = "rotate(" + String(rotations[listOfKids[0]] - 10) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[listOfKids[0]] - 10)) + "deg)";
    satellites[listOfKids[1]].style.transform = "rotate(" + String(rotations[listOfKids[1]] + 10) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[listOfKids[1]] + 10)) + "deg)";
    satellites[listOfKids[2]].style.transform = "rotate(" + String(rotations[listOfKids[2]] - 6) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[listOfKids[2]] - 6)) + "deg)";
    satellites[listOfKids[3]].style.transform = "rotate(" + String(rotations[listOfKids[3]] + 6) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[listOfKids[3]] + 6)) + "deg)";
    satellites[listOfKids[4]].style.transform = "rotate(" + String(rotations[listOfKids[4]] - 3) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[listOfKids[4]] - 6)) + "deg)";
    satellites[listOfKids[5]].style.transform = "rotate(" + String(rotations[listOfKids[5]] + 3) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[listOfKids[5]] + 6)) + "deg)";
}

export function returnToBase(){
    const rotations = [-64.26, -12.84, 38.58, 90, 141.42, 192.84, 244.26],
        satellites = document.querySelector(".satellites").children;
    let satelliteOffset = getComputedStyle(document.documentElement).getPropertyValue('--satellite-offset');
    if (window.innerWidth < 600) {
        satelliteOffset = "max(100px, 15vh)";
    }
    satellites[0].style.transform = "rotate(" + String(rotations[0]) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[0])) + "deg)";
    satellites[1].style.transform = "rotate(" + String(rotations[1]) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[1])) + "deg)";
    satellites[2].style.transform = "rotate(" + String(rotations[2]) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[2])) + "deg)";
    satellites[3].style.transform = "rotate(" + String(rotations[3]) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[3])) + "deg)";
    satellites[4].style.transform = "rotate(" + String(rotations[4]) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[4])) + "deg)";
    satellites[5].style.transform = "rotate(" + String(rotations[5]) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[5])) + "deg)";
    satellites[6].style.transform = "rotate(" + String(rotations[6]) + "deg) translate(" + String(satelliteOffset) + ") rotate(" + String((-1) * (rotations[6])) + "deg)";
}

export function enlargeSatellite(nthChild) {
    const satellite = document.querySelector(".satellites").children[nthChild];
    if (window.innerWidth < 600){
        satellite.style.setProperty('--small-satellite-size', "max(72px, 7vw)");
        satellite.style.setProperty('--satellite-size', "max(72px, 7vw)");
    } else {
        satellite.style.setProperty('--satellite-size', "min(max(120px, 8vw), 25vh)");
    }
}

export function ensmallSatellite(nthChild) {
    const satellite = document.querySelector(".satellites").children[nthChild];
    if (window.innerWidth < 600){
        satellite.style.setProperty('--small-satellite-size', "max(60px, 6vw)");
        satellite.style.setProperty('--satellite-size', "max(60px, 6vw)");
    } else {
        satellite.style.setProperty('--satellite-size', "min(max(100px, 7vw), 25vh)");
    }
}

export function setConsoleNaming(nthChild) {
    const names = ["animator", "cook", "writer", "about", "more", "bricoleur", "developer", "man"];
    const theConsole = document.querySelector(".console-bar");
    if (theConsole === null){
        return;
    }
    theConsole.innerHTML = "/> " + names[nthChild] + "";
    if (nthChild === 7){
        theConsole.style.color = "rgb(128, 128, 128)";
    } else {
        theConsole.style.color = "rgb(198, 196, 204)";
    }
}

