import {useNavigate} from "react-router-dom";
import "./Animator.css"

export default function Animator(){
    const navigate = useNavigate();
    return (
        <>
            <div className="art-background"></div>
            <div className="art-back" onClick={() => {navigate('/')}}>&lt;&lt; home</div>
            <div className="art-placeholder">
                hello.<br/><br/>
                this page is under development. hopefully, it will be updated soon.<br/>
                i've been animating for 9 years now. a few of my projects are available&nbsp;
                <a href='https://www.youtube.com/playlist?list=PLu0-Ghau7hFLouOaTqBZxMJ0ybsv5uEWr' target="_blank">here</a>.<br/>
                if you need more info, or are interested in collaborating,<br/> don't hesitate to&nbsp;
                <div onClick={() => {navigate('/info')}} style={{textDecoration: "underline", cursor: "pointer"}}>contact me</div>.
            </div>
            <div className="art-mice"></div>
        </>
    )
}